"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const ValidationError = ({ text }) => {
    if (!text) {
        return (0, jsx_runtime_1.jsx)("span", {});
    }
    else {
        return ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: `validation-${text === 'Success' ? 'success' : 'error'}` }, { children: text })));
    }
};
exports.default = ValidationError;
