"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const mobx_1 = require("mobx");
class Roles {
    getRoles() {
        return this.roles;
    }
    statusDisabled() {
        if (this.roles.includes("medical"))
            return false;
        if (this.roles.includes("emergency_management"))
            return true;
        if (this.roles.includes("student_support"))
            return true;
        if (this.roles.includes("dining"))
            return true;
        return false;
    }
}
__decorate([
    mobx_1.observable
], Roles.prototype, "roles", void 0);
const roles = new Roles();
exports.default = roles;
