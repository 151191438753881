"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_bootstrap_1 = require("react-bootstrap");
const mobx_react_1 = require("mobx-react");
const HoldStatusButtonComponent = ({ person, holds, holdGuid, currentStatus }) => {
    const handleStatusChange = (newStatus) => {
        holds.showUpdateStatusModal = true;
        holds.newStatus = newStatus;
        holds.guidToUpdate = holdGuid;
        holds.personName = person.display_name;
        holds.reason = person.reason;
    };
    const getDropDownItems = () => {
        let dropdownData = [];
        if (holds && holds.statuses) {
            holds.statuses.map((status) => {
                dropdownData.push({ text: status.text, icon: "", id: status.text });
            });
        }
        return dropdownData;
    };
    //return <Dropdown dropdownItems={getDropDownItems()} name="dropdownStatus" text={currentStatus} value={currentStatus} onChange={(value: string) => handleStatusChange(value)} />
    return (0, jsx_runtime_1.jsxs)(react_bootstrap_1.Dropdown, { children: [(0, jsx_runtime_1.jsx)(react_bootstrap_1.Dropdown.Toggle, Object.assign({ variant: 'outline-dark', id: "dropdown-basic" }, { children: currentStatus })), (0, jsx_runtime_1.jsx)(react_bootstrap_1.Dropdown.Menu, { children: holds && holds.statuses && holds.statuses.map((status) => ((0, jsx_runtime_1.jsx)(react_bootstrap_1.Dropdown.Item, Object.assign({ className: 'table-action-button', href: "#", onClick: () => (handleStatusChange(status)) }, { children: status.text }), status.id))) })] });
};
const HoldStatusButton = (0, mobx_react_1.inject)('holds')((0, mobx_react_1.observer)(HoldStatusButtonComponent));
exports.default = HoldStatusButton;
