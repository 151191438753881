"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.covidNav = exports.CovidNav = void 0;
const mobx_1 = require("mobx");
class CovidNav {
    constructor() {
        this.defaultPage = "follow-up";
        this.activePage = this.defaultPage;
        this.contactTracingStatus = [];
        this.pages = [
            {
                url: "contact-tracing",
                title: "Active Cases",
                icon: "glyphicons-basic-28-search-white.svg",
                showReasonCol: false,
                listName: "contact-tracing",
                showManifestButtons: false,
                showUploadButton: false,
                renderAction: false,
                visible: true,
            },
            {
                url: "search",
                title: "Search",
                icon: "glyphicons-basic-28-search-white.svg",
                showReasonCol: false,
                listName: "followUpList",
                showManifestButtons: false,
                showUploadButton: false,
                renderAction: false,
                visible: true,
            },
            {
                url: "follow-up",
                title: "Follow-up Required",
                icon: "glyphicons-basic-44-user-group.svg",
                showReasonCol: true,
                listName: "followUpList",
                showManifestButtons: false,
                showUploadButton: false,
                renderAction: true,
                visible: true,
            },
            {
                url: "vaccine-hold",
                title: "Vaccine Hold",
                icon: "glyphicons-basic-44-user-group.svg",
                showReasonCol: true,
                listName: "vaccineHoldList",
                showManifestButtons: false,
                showUploadButton: false,
                renderAction: true,
                visible: true,
            },
            {
                url: "pending-tests",
                title: "Pending Tests",
                icon: "glyphicons-basic-487-exchange.svg",
                showReasonCol: false,
                listName: "scheduleList",
                showManifestButtons: false,
                showUploadButton: false,
                renderAction: true,
                visible: false,
            },
            {
                url: "completed-tests",
                title: "Completed Tests",
                icon: "glyphicons-basic-634-circle-check.svg",
                showReasonCol: false,
                listName: "completedList",
                showManifestButtons: true,
                showUploadButton: false,
                showDatePicker: true,
                renderAction: false,
                visible: true,
            },
            {
                url: "flushots",
                title: "Flu Shots",
                icon: "glyphicons-basic-634-circle-check.svg",
                showReasonCol: false,
                listName: "fluShotList",
                showManifestButtons: false,
                showUploadButton: true,
                showDatePicker: true,
                renderAction: false,
                visible: true,
            },
        ];
    }
    getPage(url) {
        return this.pages.filter((p) => p.url === url)[0];
    }
}
__decorate([
    mobx_1.observable
], CovidNav.prototype, "activePage", void 0);
__decorate([
    mobx_1.observable
], CovidNav.prototype, "features", void 0);
__decorate([
    mobx_1.observable
], CovidNav.prototype, "texts", void 0);
__decorate([
    mobx_1.observable
], CovidNav.prototype, "contactTracingStatus", void 0);
__decorate([
    mobx_1.observable
], CovidNav.prototype, "globalTableIsLoading", void 0);
__decorate([
    mobx_1.observable
], CovidNav.prototype, "currentDateString", void 0);
exports.CovidNav = CovidNav;
exports.covidNav = new CovidNav();
exports.default = exports.covidNav;
