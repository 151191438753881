"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const appConfig = {
    "name": "covidmedical-web-v2",
    "version": "0.1.0",
    "stage": "prod",
    "deployThisBuild": false,
    "okta": {
        "issuer": "https://mitprod.okta.com/oauth2/aus6sh93rjqnQuszg697",
        "clientId": "1i1k313mnaedi2actr5g11cop3",
        "redirectUris": [
            "https://covidmedical-mr.atlas-apps.mit.edu"
        ],
        "postLogoutRedirectUris": [
            "https://covidmedical-mr.atlas-apps.mit.edu/logout"
        ],
        "scopes": [
            "address",
            "digital-id/search",
            "digital-id/user",
            "email",
            "offline_access",
            "openid",
            "phone",
            "profile",
            "user/admin.covid19-medical",
            "user/admin.search",
            "user/all"
        ]
    },
    "amplify": {
        "userPoolId": "us-east-1_bCu6BU8d2",
        "userPoolWebClientId": "1i1k313mnaedi2actr5g11cop3",
        "oauth": {
            "domain": "atlas-auth.mit.edu",
            "scope": [
                "account/admin",
                "covid19/impersonate",
                "covid19/medical",
                "covid19/user",
                "digital-id/search",
                "digital-id/user",
                "openid",
                "profile",
                "user/admin.covid19-access",
                "user/admin.covid19-medical",
                "user/admin.search",
                "user/all"
            ],
            "redirectUris": [
                "https://covidmedical-mr.atlas-apps.mit.edu"
            ],
            "postLogoutRedirectUris": [
                "https://covidmedical-mr.atlas-apps.mit.edu/logout"
            ],
            "responseType": "token",
            "options": {
                "AdvancedSecurityDataCollectionFlag": false
            }
        }
    }
};
exports.default = appConfig;
