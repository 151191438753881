"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const hui_1 = require("@mit/hui");
class Logout extends react_1.Component {
    render() {
        return (0, jsx_runtime_1.jsx)(hui_1.MITLoggedOut, {});
    }
}
exports.default = Logout;
