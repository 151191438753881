"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const mobx_react_1 = require("mobx-react");
const VaccineRequestsList_1 = require("./VaccineRequestsList");
const VaccineRequestsComponent = ({ covidNav, peopleStore, history }) => {
    return ((0, jsx_runtime_1.jsx)(react_1.Fragment, { children: (0, jsx_runtime_1.jsx)(mobx_react_1.Provider, Object.assign({ peopleStore: peopleStore }, { children: (0, jsx_runtime_1.jsx)(VaccineRequestsList_1.VaccineRequestsList, {}) })) }));
};
const VaccineRequests = (0, mobx_react_1.inject)("peopleStore", "covidNav")((0, mobx_react_1.observer)(VaccineRequestsComponent));
exports.default = VaccineRequests;
