"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.peopleStore = exports.PeopleStore = exports.executeSortDesc = exports.executeSort = exports.sortByTestDateFluShot = exports.sortByTestDate = exports.sortByTimeScheduled = exports.sortByTimeWaiver = exports.sortByTime = exports.sortHoldByTime = void 0;
const mobx_1 = require("mobx");
const Formatters_1 = require("../common/Formatters");
const sortHoldByTime = (a, b) => {
    return (0, exports.executeSortDesc)(a, b, 'created_on');
};
exports.sortHoldByTime = sortHoldByTime;
const sortByTime = (a, b) => {
    return (0, exports.executeSort)(a, b, 'createdOn');
};
exports.sortByTime = sortByTime;
const sortByTimeWaiver = (a, b) => {
    return (0, exports.executeSort)(a, b, 'waiverSignedOn');
};
exports.sortByTimeWaiver = sortByTimeWaiver;
const sortByTimeScheduled = (a, b) => {
    return (0, exports.executeSort)(a, b, 'nextTestDate');
};
exports.sortByTimeScheduled = sortByTimeScheduled;
const sortByTestDate = (a, b) => {
    return (0, exports.executeSortDesc)(a, b, 'testDate');
};
exports.sortByTestDate = sortByTestDate;
const sortByTestDateFluShot = (a, b) => {
    return (0, exports.executeSortDesc)(a, b, 'flu_shot_date');
};
exports.sortByTestDateFluShot = sortByTestDateFluShot;
const executeSort = (a, b, fieldName) => {
    let dateTimeA = (0, Formatters_1.toDate)(a[fieldName]);
    let dateTimeB = (0, Formatters_1.toDate)(b[fieldName]);
    return dateTimeA.diff(dateTimeB);
};
exports.executeSort = executeSort;
const executeSortDesc = (a, b, fieldName) => {
    let dateTimeA = (0, Formatters_1.toDate)(a[fieldName]);
    let dateTimeB = (0, Formatters_1.toDate)(b[fieldName]);
    return dateTimeB.diff(dateTimeA);
};
exports.executeSortDesc = executeSortDesc;
class PeopleStore {
    constructor() {
        this.scheduleList = [];
        this.testResultList = [];
    }
    setFollowUpList(people) {
        this.followUpList = people.sort(exports.sortHoldByTime);
        // console.log(
        //     'settingFollowupList', people, this.followUpList
        // );
    }
    setVaccineHoldList(people) {
        this.vaccineHoldList = people.sort(exports.sortHoldByTime);
    }
    setVaccineExemptionList(people) {
        this.vaccineExemptList = people.sort(exports.sortHoldByTime);
    }
    setVaccineRequestExemptionList(people) {
        this.vaccineRequestExemptList = people;
    }
    setScheduleList(people) {
        this.scheduleList = people.filter((item) => !item.nextTestDate).sort(exports.sortByTimeWaiver);
    }
    setTestResultList(people) {
        this.testResultList = people.filter((item) => item.nextTestDate).sort(exports.sortByTimeScheduled);
    }
    setCompletedList(people) {
        this.completedList = people.sort(exports.sortByTestDate);
    }
    setFluShotList(people) {
        this.fluShotsList = people.sort(exports.sortByTestDateFluShot);
    }
    setVaccineList(people) {
        this.vaccineList = people.sort(exports.sortByTestDateFluShot);
    }
    setContactTracingList(people) {
        this.contactTracingList = people;
    }
}
__decorate([
    mobx_1.observable
], PeopleStore.prototype, "vaccineHoldList", void 0);
__decorate([
    mobx_1.observable
], PeopleStore.prototype, "vaccineExemptList", void 0);
__decorate([
    mobx_1.observable
], PeopleStore.prototype, "vaccineRequestExemptList", void 0);
__decorate([
    mobx_1.observable
], PeopleStore.prototype, "scheduleList", void 0);
__decorate([
    mobx_1.observable
], PeopleStore.prototype, "testResultList", void 0);
__decorate([
    mobx_1.observable
], PeopleStore.prototype, "completedList", void 0);
__decorate([
    mobx_1.observable
], PeopleStore.prototype, "fluShotsList", void 0);
__decorate([
    mobx_1.observable
], PeopleStore.prototype, "vaccineList", void 0);
__decorate([
    mobx_1.observable
], PeopleStore.prototype, "contactTracingList", void 0);
__decorate([
    mobx_1.action
], PeopleStore.prototype, "setFollowUpList", null);
__decorate([
    mobx_1.action
], PeopleStore.prototype, "setVaccineHoldList", null);
__decorate([
    mobx_1.action
], PeopleStore.prototype, "setVaccineExemptionList", null);
__decorate([
    mobx_1.action
], PeopleStore.prototype, "setVaccineRequestExemptionList", null);
__decorate([
    mobx_1.action
], PeopleStore.prototype, "setScheduleList", null);
__decorate([
    mobx_1.action
], PeopleStore.prototype, "setTestResultList", null);
__decorate([
    mobx_1.action
], PeopleStore.prototype, "setCompletedList", null);
__decorate([
    mobx_1.action
], PeopleStore.prototype, "setFluShotList", null);
__decorate([
    mobx_1.action
], PeopleStore.prototype, "setVaccineList", null);
__decorate([
    mobx_1.action
], PeopleStore.prototype, "setContactTracingList", null);
exports.PeopleStore = PeopleStore;
exports.peopleStore = new PeopleStore();
