"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sentenceCase = exports.firstLetterUpper = exports.getResultText = exports.formatPhoneNumber = exports.toUtcDate = exports.toUtc = exports.toLocalTimeFromUct = exports.displayDateShort = exports.displayDateTimeShort = exports.displayDate = exports.displayTime = exports.displayDateTimeLong = exports.usDateTime = exports.displayDateTime = exports.toDate = exports.simpleDate = exports.mitFormatToday = exports.shortToday = exports.simpleToday = void 0;
const moment = require("moment");
const simpleToday = () => {
    let displayDate = moment().format("MMM D");
    return `${displayDate}`;
};
exports.simpleToday = simpleToday;
const shortToday = () => {
    let displayDate = moment().format("MMM D, YYYY");
    return `${displayDate}`;
};
exports.shortToday = shortToday;
const mitFormatToday = () => {
    return moment().format("MM/DD/YYYY");
};
exports.mitFormatToday = mitFormatToday;
const simpleDate = (d) => {
    return "May 21";
    //return moment(d, 'MM/DD/YYYY').format('MMM D')
};
exports.simpleDate = simpleDate;
const toDate = (dateTime) => {
    return moment(dateTime, "YYYY-MM-DD H:mm:ss");
};
exports.toDate = toDate;
const displayDateTime = (date) => {
    if (!date) {
        return "-";
    }
    if (typeof date === "string") {
        date = moment(date);
    }
    let displayDate = moment(date).format("LLL");
    return `${displayDate}`;
};
exports.displayDateTime = displayDateTime;
const usDateTime = (date) => {
    if (!date) {
        return "-";
    }
    let displayDate = moment.utc(date).local();
    return `${displayDate.format("MM/DD/YYYY hh:mm A")}`;
};
exports.usDateTime = usDateTime;
const displayDateTimeLong = (date) => {
    if (!date) {
        return "-";
    }
    let displayDate = moment(date, "YYYY-MM-DDTHH:mm:ssZ");
    return `${displayDate.format("MM/DD/YYYY hh:mm A")}`;
};
exports.displayDateTimeLong = displayDateTimeLong;
const displayTime = (date) => {
    if (!date) {
        return "-";
    }
    let displayDate = moment(date, "YYYY-MM-DDTHH:mm:ssZ");
    return `${displayDate.format("hh:mm A")}`;
};
exports.displayTime = displayTime;
const displayDate = (date) => {
    if (typeof date === "string") {
        date = moment(date);
    }
    let displayDate = moment(date).format("MM/DD/YYYY");
    return `${displayDate}`;
};
exports.displayDate = displayDate;
const displayDateTimeShort = (date) => {
    if (!date) {
        return "-";
    }
    if (typeof date === "string") {
        date = moment(date);
    }
    let displayDate = moment(date).format("MMM D, YYYY H:mm A");
    return `${displayDate}`;
};
exports.displayDateTimeShort = displayDateTimeShort;
const displayDateShort = (date) => {
    if (!date) {
        return "-";
    }
    if (typeof date === "string") {
        date = moment(date);
    }
    let displayDate = moment(date).format("MMM D, YYYY");
    return `${displayDate}`;
};
exports.displayDateShort = displayDateShort;
const toLocalTimeFromUct = (utcTime) => {
    var date = moment.utc(utcTime).format();
    let local = moment.utc(date).local();
    return local.format();
};
exports.toLocalTimeFromUct = toLocalTimeFromUct;
const toUtc = (localTime) => {
    return moment.utc(moment(localTime)).format();
};
exports.toUtc = toUtc;
const toUtcDate = (localTime) => {
    return moment.utc(localTime).format();
};
exports.toUtcDate = toUtcDate;
const formatPhoneNumber = (number) => {
    if (number) {
        let cleaned = ("" + number).replace(/\D/g, "");
        let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            let intlCode = match[1] ? "+1 " : "";
            return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
        }
    }
    return number;
};
exports.formatPhoneNumber = formatPhoneNumber;
const getResultText = (abbr, extraInfo) => {
    var _a, _b;
    switch (abbr) {
        case "P":
            return "Positive";
        case "N":
            return "Negative";
        case "I":
            return "Invalid";
        case "FLU_SHOT":
            return "Flu Shot";
        case "VACCINE_SHOT":
            return `${(_a = extraInfo === null || extraInfo === void 0 ? void 0 : extraInfo.test_company) !== null && _a !== void 0 ? _a : "Pfizer"} shot 1`;
        case "VACCINE_COURSE_COMPLETED":
            return `${(_b = extraInfo === null || extraInfo === void 0 ? void 0 : extraInfo.test_company) !== null && _b !== void 0 ? _b : "Pfizer"} shot 2`;
    }
    return "Pending";
};
exports.getResultText = getResultText;
const firstLetterUpper = (theString) => {
    var newString = theString.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase(); });
    return newString;
};
exports.firstLetterUpper = firstLetterUpper;
const sentenceCase = (str) => { return str.replace(/[a-z]/i, function (letter) { return letter.toUpperCase(); }).replace(/_/g, " ").trim(); };
exports.sentenceCase = sentenceCase;
