"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const hui_1 = require("@mit/hui");
const UnauthModal = ({ show, onClose }) => {
    const handleClose = () => {
        onClose && onClose();
    };
    return (0, jsx_runtime_1.jsx)(hui_1.TemplateModal, { size: hui_1.TemplateModalSize.Small, body: (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "p-3" }, { children: (0, jsx_runtime_1.jsx)(hui_1.Error, { context: hui_1.ErrorContext.Component, message: "Not Authorized", type: hui_1.ErrorType.AccessDenied }) })), bodyAlignment: hui_1.TemplateModalAlignment.Center, footer: (0, jsx_runtime_1.jsx)("div", {}), name: "unauthModal", show: show, header: (0, jsx_runtime_1.jsx)(hui_1.Text, { content: "Not Authorized" }), onClose: handleClose });
};
exports.default = UnauthModal;
