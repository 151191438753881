"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const apiConfigs = {
    "regional": {
        "apiHost": "https://api.mit.edu",
        "paths": {
            "apiPathCovid": "covid19-v1",
            "apiPathDigitalId": "digital-id-v1",
            "apiPathMedical": "medical-v1",
            "apiPathPass": "pass-v1",
            "apiPathVaccine": "vaccine-v1",
            "apiPathFeature": "feature-v1",
            "apiPathVaccineV2": "vaccine-v2"
        }
    },
    "global": {
        "apiHost": "https://global.api.mit.edu",
        "paths": {
            "apiPathCovid": "covid19/v1",
            "apiPathDigitalId": "digital-id/v1",
            "apiPathMedical": "medical/v1",
            "apiPathPass": "pass/v1",
            "apiPathVaccine": "vaccine/v1",
            "apiPathFeature": "feature/v1",
            "apiPathVaccineV2": "vaccine/v2"
        }
    }
};
exports.default = apiConfigs;
