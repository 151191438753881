"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const easy_peasy_1 = require("easy-peasy");
const model = {
    casesList: [],
    setCasesList: (0, easy_peasy_1.action)((state, payload) => {
        state.casesList = payload;
    })
};
const store = (0, easy_peasy_1.createContextStore)(model, {
    name: 'contactTracingStore',
    devTools: true
});
exports.default = store;
