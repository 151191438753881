"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const mobx_1 = require("mobx");
// import { PageError } from "types/PageErrors";
// export interface AppInterface {
// 	isLoading: boolean;
//     pageError: PageError;
// }
class AppStore {
    constructor() {
        this.isLoading = true;
        this.pageError = { error: false, errorText: "" };
        (0, mobx_1.makeObservable)(this, {
            isLoading: mobx_1.observable,
            setPageError: mobx_1.action,
            pageError: mobx_1.observable,
            setIsLoading: mobx_1.action
        });
    }
    setPageError(error, errorText) {
        this.pageError = { error, errorText };
    }
    setIsLoading(isLoading) {
        this.isLoading = isLoading;
    }
}
const appStore = new AppStore();
exports.default = appStore;
