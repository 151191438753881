"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultListData = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const hui_1 = require("@mit/hui");
exports.defaultListData = [
    {
        date: "11/30/2020",
        text: "",
        items: [{ heading: "Added Contact Tracing", description: "An active case allows for the addition of individuals the patient have been in contact with." }],
    },
    {
        date: "11/10/2020",
        text: "",
        items: [
            { heading: "Upgraded User Interface", description: "Improved search and navigation. Added additional info per user and the ability to exempt an individual from testing." },
            { heading: "Improved Bulk Upload", description: "Improved bulk upload validation by allowing realtime fixes before final submission." },
        ],
    },
    {
        date: '02/13/2021',
        text: '',
        items: [
            { heading: "Improved Travel Interface", description: "Improved the experience of Travel app to be more rich and interactive, making use of maps and adding more options. Also added passport and visas to profile." },
        ]
    },
    {
        date: '02/21/2021',
        text: '',
        items: [
            { heading: "Improved Travel Process", description: "Added High Risk travel workflow requirements that goes through an approval process." },
        ]
    },
];
const ReleaseNotesComponent = ({ onClose, show }) => {
    return ((0, jsx_runtime_1.jsx)(hui_1.Drawer, { contentAriaLabel: `What's New Release Notes`, type: hui_1.DrawerType.Right, contents: (0, jsx_runtime_1.jsx)(hui_1.ReleaseNotes, { items: exports.defaultListData }), onClose: onClose, show: show, size: hui_1.DrawerSize.Medium, footer: [], themedColor: "medical", header: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(hui_1.Text, { content: "What's New", type: hui_1.TextType.Heading3 }) }) }));
};
exports.default = ReleaseNotesComponent;
